<template>
    <v-content>
        <div class="container">
            <v-breadcrumbs :items="snackbar.breadcrumbItems" large></v-breadcrumbs>
            <div slot="table-actions">
                <div class="my-1">
                    <v-btn color="primary" @click.stop="addModal">Column Form</v-btn>
                </div>
            </div>
            <vue-good-table
                    :columns="columns"
                    :rows="rows"
                    mode="remote"
                    :totalRows="mountainData.totalRecords"
                    :pagination-options="{ enabled: true,mode: 'records', perPageDropdown: [50, 100,200] }"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    styleClass="vgt-table condensed"
            >
                <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'establishedYear'">
                  <span>{{props.row.establishedYear | moment("DD-MMM-YYYY, h:mm:ss a")}}</span>
                </span>
                    <span v-else-if="props.column.field == 'actions'">
                  <v-icon small class="mr-2" @click.stop="editItem(props.row)">edit</v-icon>
                        <!-- <v-icon small @click="deleteItem(props.row)">delete</v-icon>-->
                </span>
                </template>
            </vue-good-table>
            <v-dialog
                    v-model="snackbar.formModal"
                    transition="dialog-bottom-transition"
                    max-width="500px"
                    persistent
            >
                <v-card>
                    <MountainForm @dialogueClose="dialogueClose"
                                  @formResponse="formResponse"
                                  v-if="snackbar.createFormComponent"
                    ></MountainForm>
                    <MountainEditForm @dialogueClose="dialogueClose"
                                      @formResponse="formResponse"
                                      v-if="snackbar.editFormComponent"
                                      :editItemIndex="mountainData.editItemIndex"
                    ></MountainEditForm>
                </v-card>
            </v-dialog>
            <v-snackbar
                    v-model="snackbar.snackbar"
                    :color="snackbar.color"
                    :timeout="snackbar.timeout"
                    top
            >
                {{ snackbar.text }}
                <v-btn
                        dark
                        text
                        @click="snackbar.snackbar = false"
                >
                    Close
                </v-btn>
            </v-snackbar>
            <v-dialog v-model="snackbar.dialogDelete" max-width="500px">
                <deleteListData :deleteFormData="mountainData.deleteFormData"
                                @formResponse="onResponseDelete"></deleteListData>
            </v-dialog>
        </div>
    </v-content>
</template>

<script>
    import axios from "axios";
    import MountainForm from "./MountainForm";
    import MountainEditForm from "./MountainEditForm";
    import deleteListData from "@/components/deleteModal";

    export default {
        name: "MountainList",
        components: {
            'MountainForm': MountainForm,
            'MountainEditForm': MountainEditForm,
            'deleteListData': deleteListData
        },
        computed: {},
        data() {
            return {
                columns: [
                    {
                        label: "S No.",
                        field: "countIndex"
                    },
                    {
                        label: 'Column Name',
                        field: 'columnName',
                    },
                    {
                        label: 'Column Initial Value',
                        field: 'columnValue',
                    },
                    {label: "Actions", field: "actions"}
                ],
                rows: [],
                mountainData: {
                    totalRows: 0,
                    editItemIndex: null,
                    deleteFormData: {}
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 50,
                    MountainName: ""
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    dialogDelete: false,
                    breadcrumbItems: [
                        {
                            text: 'Settings',
                            to: '/MountaineeringSettings'
                        },
                        {
                            text: 'Column List',
                            disabled: true,
                        },
                    ],
                    dynamicComponent: {
                        current: 'MountainForm'
                    }
                }
            };
        },
        methods: {
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
            editItem({id}) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.mountainData.editItemIndex = id
            },
            deleteItem({id}) {
                this.snackbar.dialogDelete = true;
                this.mountainData.deleteFormData.url = "Mountain/Delete/" + id;
                this.loadItems();
            },
            onResponseDelete() {
                this.snackbar.dialogDelete = false;
                this.loadItems();
            },
            formResponse(responseObj) {
                this.snackbar.color = responseObj.color ? responseObj.color : 'red'
                this.snackbar.text = responseObj.message ? responseObj.message : 'Server Error'
                this.snackbar.snackbar = true
                this.loadItems();
            },
            dialogueClose() {
                this.mountainData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
                this.loadItems()
            },
            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    MountainName: this.serverParams.MountainName,
                };
                axios.post('Mountaining/GetMountainListAsyc', param).then(response => {
                    this.rows = response.data.data;
                    this.totalRecords = response.data.totalCount;
                })
                    .catch(err => {
                        console.log(err)
                    });

                this.snackbar.loading = false;
            },
        },
        mounted() {
            this.loadItems()
        },

    }
</script>

<style scoped>

</style>
